body {
  height: 100vh;
}

div[class='HoverCard'],
div[class='SocialProof'],
div[class='Feedback'],
span[class='FollowerCountText'],
a[class='ViewProfileButton'],
a[class='CaptionCommentsExpand'] {
  display: none !important;
}

/* Fix position of caption after clutter removal */
div[class='Caption'] {
  padding-top: 10px !important;
}

/* Make dots for multiple-photo posts appear inside the photo, rather than
 * on top of the caption, after clutter removal. If you want to hide the dots
 * altogether (they are normally hidden on embeds by the hovercard launcher),
 * replace "margin-top: -15px" in the next line with "display: none".
 */
div[class='EmbedSidecar'] div[class*='ijCUd'] {
  margin-top: -15px !important;
}

/* Shrink the embed to fit the new content size after clutter removal */
div[data-oembed-url*="https://www.instagram.com/"] {
  height: auto !important;
}

.btn-link-secondary:focus,
.btn-link-secondary:active {
  box-shadow: none !important;
}
